export default {
    website_name: 'LiveCrowd',
    texts: {
        heading: 'Convert and upsell your visitors in real time',
        screenshot: null,
        subtitle: 'See in real-time what products your customers are viewing and searching for - then send them targeted and custom-made notifications to convert and upsell.',
        cta1: 'Get Started',
        cta_title: 'Turn your visitors into paying customers in real time.',
        cta2: 'Get Started',
        features: [
            { name: 'Increase Conversion Rate', icon: require('./assets/images/feature-tile-icon-01.svg'), description: 'Convert your visitors in real-time with one click - custom-made tools, from sale notifications to creating trust and urgency, we\'ve got all the well known tools.' },
            { name: 'Increase AOV', icon: require('./assets/images/feature-tile-icon-02.svg'), description: 'Increase Average Order Value - send laser focused offers to your visitors, to both upsell and cross-sell additional products.' },
            { name: 'Easily Find What Converts the Best', icon: require('./assets/images/feature-tile-icon-03.svg'), description: 'Each store is different. Find out what tools and methods work best for you, easily and quickly - in real-time.' },
            { name: 'Automate Methods That Work', icon: require('./assets/images/feature-tile-icon-04.svg'), description: 'You can choose to automate any methods and workflows that work best for your shop.' },
            { name: 'Discover Which Products Are More Well Liked', icon: require('./assets/images/feature-tile-icon-05.svg'), description: 'See which products your visitors search for and look at - get detailed reports about customer journeys, so you can better optimize your catalog.' },
            { name: 'Understand Your Customer Better', icon: require('./assets/images/feature-tile-icon-06.svg'), description: 'See in real time where your customers go and what they do - this gives you better understanding than any other analytics tool out there.' },
            { name: 'Encourage Customers to Share Your Website', icon: require('./assets/images/feature-tile-icon-01.svg'), description: 'Send visitors notifications that encourage them to share with friends for discounts.' },
        ]
    },
    ga: {
        trackingId: 'UA-186325429-1',
        debug: true,
        gaOptions: {
            cookieDomain: 'none'
        }
    }
};
